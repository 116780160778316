import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import Container from '@components/Container'
import { ListWrapper } from './index.style'
import Filters from '@components/Articles/Filters'
import Items from '@components/Articles/Items'
import { checkFilterMatch, getTagsFromHash, anchorScroll } from '@utils'
import Spacer from '@components/Spacer'
import { useStore } from '@Store'

const List = ({ tags, articles, linkLabel, types }) => {
  const [filteredArticles, setFilteredArticles] = useState(articles)
  const [activeFilters, setActiveFilters] = useState([])
  const listRef = useRef()
  const [store] = useStore()
  const { smoothScroll } = store

  useEffect(() => {
    const matchingResults = articles.flatMap(article => {
      const filterMatch =
        activeFilters.length === 0 ||
        checkFilterMatch(article.metadata.tags, activeFilters)

      return filterMatch ? [article] : []
    })

    setFilteredArticles(matchingResults)
  }, [articles, activeFilters])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const filtersTags = params.get('filters')

    if (filtersTags) {
      const foundTags = getTagsFromHash(tags, filtersTags)

      if (foundTags) {
        const activeTags = foundTags.map(tag => tag.name)

        setActiveFilters(activeTags)
      }

      if (smoothScroll) {
        setTimeout(() => {
          anchorScroll(smoothScroll, listRef.current.offsetTop - 110)
        }, 1000)
      }
    }
  }, [setActiveFilters, smoothScroll, tags])

  return (
    <ListWrapper ref={listRef}>
      <Container>
        {tags && (
          <>
            <Spacer size={[28, 48]} />
            <Filters
              activeFilters={activeFilters}
              setActiveFilters={setActiveFilters}
              tags={tags}
            />
          </>
        )}
        <Items
          articles={filteredArticles}
          linkLabel={linkLabel}
          types={types}
        />
      </Container>
    </ListWrapper>
  )
}

List.propTypes = {
  tags: PropTypes.array,
  articles: PropTypes.array,
  linkLabel: PropTypes.string,
  types: PropTypes.string,
}

export default List
