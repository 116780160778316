import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import {
  ItemsWrapper,
  ItemContent,
  ItemsPaginator,
  ItemsSeperator,
} from './index.style'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import RoundedImage from '@components/RoundedImage'
import { Heading3, TextBodySmall } from '@components/TextStyles'
import { GatsbyImage } from 'gatsby-plugin-image'
import Spacer from '@components/Spacer'
import Button from '@components/Button'
import PageTransitionLink from '@components/PageTransitionLink'
import Link from '@components/Link'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import AnimateFadeIn from '@components/animation/AnimateFadeIn'
import AnimateImage from '@components/animation/AnimateImage'

const Items = ({ articles, linkLabel, types }) => {
  const [itemCount, setItemCount] = useState(6)

  const addPosts = useCallback(() => {
    setItemCount(itemCount + 6)
  }, [itemCount])

  return (
    <ItemsWrapper>
      <Grid>
        {React.Children.toArray(
          articles.slice(0, itemCount).map(article => {
            return (
              <GridItem tabletP={6} desk={4}>
                {article.introImage && (
                  <RoundedImage>
                    <PageTransitionLink toPage={article}>
                      <AnimateImage delay={0}>
                        <GatsbyImage
                          image={article.thumbnail ? article.thumbnail.gatsbyImageData : article.introImage.gatsbyImageData}
                          alt={article.thumbnail ? article.thumbnail.description : article.introImage.description}
                        />
                      </AnimateImage>
                    </PageTransitionLink>
                  </RoundedImage>
                )}
                <ItemContent>
                  <Heading3>
                    <AnimateSplitText>{article.title}</AnimateSplitText>
                  </Heading3>
                  {article.excerpt && (
                    <>
                      <Spacer size={[24, 12]} />
                      <TextBodySmall>{article.excerpt}</TextBodySmall>
                    </>
                  )}
                  <Spacer size={36} />
                  <AnimateFadeIn>
                    <Link page={article}>{linkLabel}</Link>
                  </AnimateFadeIn>
                </ItemContent>
              </GridItem>
            )
          })
        )}
      </Grid>
      {itemCount < articles.length && (
        <ItemsPaginator>
          <ItemsSeperator />
          <Spacer size={[36, 114]} />
          <Button type="button" onClick={addPosts}>
            Load More {types}
          </Button>
          <Spacer size={[36, 100]} />
        </ItemsPaginator>
      )}
    </ItemsWrapper>
  )
}

Items.propTypes = {
  articles: PropTypes.array,
  linkLabel: PropTypes.string,
  types: PropTypes.string,
}

export default Items
