import React from 'react'
import { graphql } from 'gatsby'
import Seo from '@components/Seo'
import Footer from '@components/Footer'
import TitleContent from '@components/TitleContent'
import RichFeaturedLinks from '@components/RichFeaturedLinks'
import List from '@components/Articles/List'
import Links from '@components/Articles/Links'
import Terms from '@components/Article/Terms'

const OffersPage = ({ data, location }) => {
  const {
    slug,
    seo,
    intro,
    richFeaturedOffers,
    featuredOffersList,
    termsConditions,
  } = data.contentfulOffers

  return (
    <>
      <Seo data={seo} slug={slug} />
      <TitleContent
        title={intro.title}
        content={intro.content}
        alignment={intro.alignmentDesktop}
      />
      <RichFeaturedLinks blocks={richFeaturedOffers} />
      {featuredOffersList && (
        <Links
          title="Our Featured Offers"
          subtitle="For the latest offers in market right now, check out the below:"
          links={featuredOffersList}
        />
      )}
      <List
        articles={data.allContentfulOffer.nodes}
        linkLabel="View Offer"
        types="Offers"
      />
      {termsConditions && <Terms content={termsConditions} />}
      <Footer location={location} />
    </>
  )
}

export default OffersPage

export const pageQuery = graphql`
  query OffersPage {
    contentfulOffers {
      slug
      seo {
        ...SEO
      }
      intro {
        ...TitleContentBlock
      }
      richFeaturedOffers {
        ...ImageContentBlock
      }
      featuredOffersList {
        internal {
          type
        }
        slug
        title
      }
      termsConditions {
        raw
      }
    }
    allContentfulOffer(
      sort: { fields: [pinned, createdAt], order: [DESC, DESC] }
    ) {
      nodes {
        contentful_id
        createdAt
        internal {
          type
        }
        pinned
        title
        slug
        introImage {
          gatsbyImageData(aspectRatio: 1.6)
          description
        }
        thumbnail {
          gatsbyImageData(aspectRatio: 1.6)
          description
        }
      }
    }
  }
`
