import React from 'react'
import PropTypes from 'prop-types'
import Container from '@components/Container'
import {
  LinksMain,
  LinksWrapper,
  LinksInner,
  LinksList,
  LinksListItem,
} from './index.style'
import { Heading1, TextBody } from '@components/TextStyles'
import Spacer from '@components/Spacer'
import Link from '@components/Link'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import AnimateFadeIn from '@components/animation/AnimateFadeIn'
import { useInView } from 'react-intersection-observer'
import { animation } from '@styles/vars/animation.style'

const Links = ({ title, subtitle, links }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: animation.rootMargin,
  })

  return (
    <LinksWrapper ref={ref} inView={inView}>
      <Container>
        <LinksMain>
          <LinksInner>
            <AnimateSplitText>
              <Heading1>{title}</Heading1>
            </AnimateSplitText>
            <Spacer size={20} />
            <AnimateSplitText>
              <TextBody>{subtitle}</TextBody>
            </AnimateSplitText>
            <Spacer size={[24, 58]} />
          </LinksInner>
          <hr />
          <Spacer size={[24, 58]} />
          <LinksList>
            {React.Children.toArray(
              links.map(link => {
                return (
                  <LinksListItem>
                    {!link.url ? (
                      <AnimateFadeIn>
                        <Link page={link}>{link.title}</Link>
                      </AnimateFadeIn>
                    ) : (
                      <AnimateFadeIn>
                        <Link iconOrientation="vertical" url={link.url}>{link.text}</Link>
                      </AnimateFadeIn>
                    )}
                  </LinksListItem>
                )
              })
            )}
          </LinksList>
        </LinksMain>
      </Container>
    </LinksWrapper>
  )
}

Links.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  links: PropTypes.array,
}

export default Links
