import styled from 'styled-components'
import { clamp } from '@styles/utils/conversion.style'
import { colors } from '@styles/vars/colors.style'
import { mq } from '@styles/vars/media-queries.style'
import { cubicBezier } from '@styles/vars/cubicBezier.style'

export const LinksWrapper = styled.div`
  ${clamp('padding-bottom', 72, 200)};

  hr {
    transform-origin: top left;
    background-color: ${colors.blueborder};
    transition: transform 0.7s ${cubicBezier.inOut}, opacity 0.7s ${cubicBezier.inOut};
    transform: ${props => props.inView ? 'scaleX(1)' : 'scaleX(0)' } ;
    will-change: transform;
    opacity: ${props => props.inView ? '1' : '0' } ;
  }
`

export const LinksMain = styled.div`
  background-color: ${colors.blue};
  ${clamp('border-radius', 12, 24)};
  color: ${colors.light};
  overflow: hidden;
  ${clamp('padding-bottom', 45, 60)};
  ${clamp('padding-top', 40, 72)};
  text-align: center;
`

export const LinksInner = styled.div`
  padding: 0 2.4rem;

  br {
    ${mq.tabletL} {
      display: none;
    }
  }
`

export const LinksList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style-type: none;
  padding: 0 2.4rem;

  ${mq.tabletP} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

export const LinksListItem = styled.li`
  padding-bottom: 2.4rem;

  ${mq.tabletP} {
    padding: 0 0.9rem 1.8rem;
  }

  &:last-child {
    padding-bottom: 0;

    ${mq.tabletP} {
      padding-bottom: 1.8rem;
    }
  }
`
